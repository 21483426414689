import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { useEffect, useState } from 'react';

export const useUtmParams = () => {
    const [utmParams, setUtmParams] = useState<ParsedUrlQuery | null>(null);
    const router = useRouter();

    useEffect(() => {
        if (!router.isReady) return;

        // Получаем query из router
        const query: ParsedUrlQuery = router.query;

        // Проверяем, есть ли хотя бы одна UTM-метка
        const hasUtmParams = Object.values(query).some((value) => value !== undefined && value !== null);

        if (hasUtmParams) {
            localStorage.setItem('utmParams', JSON.stringify(query));
            setUtmParams(query);
        } else {
            // Если нет ни одной UTM-метки, устанавливаем состояние в null
            setUtmParams(null);
        }
    }, [router.isReady, router.query]);

    return utmParams;
};
