import { MotionValue, m as motion, useTransform } from 'framer-motion';
import React, { useEffect, useRef } from 'react';

interface Props {
    scrollYProgress: MotionValue;
}

const GradientBG = ({ scrollYProgress }: Props) => {
    const zIndex = useTransform(scrollYProgress, [0, 1], [1, 3]);
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        let rAF: number;
        let program: WebGLProgram | null | undefined;
        let positionBuffer: WebGLBuffer | null;
        let vertexShader: WebGLShader | null | undefined;
        let fragmentShader: WebGLShader | null | undefined;

        const gl = canvas?.getContext('webgl', {
            antialias: false,
            powerPreference: 'high-performance',
        }) as WebGLRenderingContext;

        const vertexShaderSource = /* glsl */ `
                    attribute vec4 aPosition;
                    void main() {
                        gl_Position = aPosition;
                    }
                `;

        const fragmentShaderSource = /* glsl */ `
                    precision highp float;
                    uniform float uTime;
                    uniform vec2 iResolution;
        
                    #define S(a,b,t) smoothstep(a,b,t)
        
                    mat2 Rot(float a) {
                        float s = sin(a);
                        float c = cos(a);
                        return mat2(c, -s, s, c);
                    }
        
                    vec2 hash(vec2 p) {
                        p = vec2(dot(p, vec2(2127.1, 81.17)), dot(p, vec2(1269.5, 283.37)));
                        return fract(sin(p) * 43758.5453);
                    }
        
                    float noise(in vec2 p) {
                        vec2 i = floor(p);
                        vec2 f = fract(p);
                        vec2 u = f * f * (3.0 - 2.0 * f);
                        float n = mix(
                            mix(dot(-1.0 + 2.0 * hash(i + vec2(0.0, 0.0)), f - vec2(0.0, 0.0)),
                                dot(-1.0 + 2.0 * hash(i + vec2(1.0, 0.0)), f - vec2(1.0, 0.0)), u.x),
                            mix(dot(-1.0 + 2.0 * hash(i + vec2(0.0, 1.0)), f - vec2(0.0, 1.0)),
                                dot(-1.0 + 2.0 * hash(i + vec2(1.0, 1.0)), f - vec2(1.0, 1.0)), u.x), u.y);
                        return 0.5 + 0.5 * n * 0.5;
                    }
        
                    void mainImage(out vec4 fragColor, in vec2 fragCoord) {
                        vec2 uv = fragCoord / iResolution.xy;
                        float ratio = iResolution.x / iResolution.y;
        
                        vec2 tuv = uv;
                        tuv -= .5;
        
                        float degree = noise(vec2(uTime * .1, tuv.x * tuv.y));
                        tuv.y *= 1. / ratio;
                        tuv *= Rot(radians((degree - .5) * 720. + 180.));
                        tuv.y *= ratio;
        
                        float frequency = 5.;
                        float amplitude = 30.;
                        float speed = uTime * 2.;
                        tuv.x += sin(tuv.y * frequency + speed) / amplitude;
                        tuv.y += sin(tuv.x * frequency * 1.5 + speed) / (amplitude * .5);
        
                        vec3 colorYellow = vec3(.0, .0, .623);
                        vec3 colorDeepBlue = vec3(.192, .384, .933);
                        vec3 layer1 = mix(colorYellow, colorDeepBlue, S(-.3, .2, (tuv * Rot(radians(-5.))).x));
        
                        vec3 colorRed = vec3(.0, .0, .0);
                        vec3 colorBlue = vec3(0.0, .0, .623);
                        vec3 layer2 = mix(colorRed, colorBlue, S(-.3, .2, (tuv * Rot(radians(-5.))).x));
        
                        vec3 finalComp = mix(layer1, layer2, S(.5, -.3, tuv.y));
        
                        fragColor = vec4(finalComp, 1.0);
                    }
        
                    void main() {
                        mainImage(gl_FragColor, gl_FragCoord.xy);
                    }
                `;

        function createShader(gl: WebGLRenderingContext, type: number, source: string) {
            const shader = gl.createShader(type);
            if (shader) {
                gl.shaderSource(shader, source);
                gl.compileShader(shader);
                if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
                    console.error('Shader compile failed with: ' + gl.getShaderInfoLog(shader));
                    gl.deleteShader(shader);
                    return null;
                }
                return shader;
            }
        }

        function createProgram(gl: WebGLRenderingContext, vertexShader: WebGLShader, fragmentShader: WebGLShader) {
            const program = gl.createProgram();

            if (program) {
                gl.attachShader(program, vertexShader);
                gl.attachShader(program, fragmentShader);
                gl.linkProgram(program);
                if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
                    gl.deleteProgram(program);
                    return null;
                }
                return program;
            }
        }

        function resizeCanvas() {
            if (canvas && gl) {
                canvas.width = window.innerWidth;
                canvas.height = window.innerHeight;
                gl.viewport(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight);
            }
        }

        if (gl) {
            vertexShader = createShader(gl, gl.VERTEX_SHADER, vertexShaderSource);
            fragmentShader = createShader(gl, gl.FRAGMENT_SHADER, fragmentShaderSource);

            if (vertexShader && fragmentShader) {
                program = createProgram(gl, vertexShader, fragmentShader);

                if (program) {
                    positionBuffer = gl.createBuffer();
                    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
                    gl.bufferData(
                        gl.ARRAY_BUFFER,
                        new Float32Array([-1, -1, 1, -1, -1, 1, -1, 1, 1, -1, 1, 1]),
                        gl.STATIC_DRAW,
                    );

                    const positionLocation = gl.getAttribLocation(program, 'aPosition');
                    const resolutionLocation = gl.getUniformLocation(program, 'iResolution');
                    const timeLocation = gl.getUniformLocation(program, 'uTime');

                    function render(time: number) {
                        time *= 0.001;
                        if (gl && program) {
                            gl.clear(gl.COLOR_BUFFER_BIT);

                            gl.useProgram(program);

                            gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
                            gl.enableVertexAttribArray(positionLocation);
                            gl.vertexAttribPointer(positionLocation, 2, gl.FLOAT, false, 0, 0);

                            gl.uniform2f(resolutionLocation, gl.drawingBufferWidth, gl.drawingBufferHeight);
                            gl.uniform1f(timeLocation, time);

                            gl.drawArrays(gl.TRIANGLES, 0, 6);
                        }

                        rAF = requestAnimationFrame(render);
                    }
                    window.addEventListener('resize', resizeCanvas);
                    resizeCanvas();
                    rAF = requestAnimationFrame(render);
                }
            }
        }

        return () => {
            cancelAnimationFrame(rAF);
            window.removeEventListener('resize', resizeCanvas);

            if (gl) {
                if (program) {
                    gl.deleteProgram(program);
                }

                if (vertexShader) {
                    gl.deleteShader(vertexShader);
                }

                if (fragmentShader) {
                    gl.deleteShader(fragmentShader);
                }

                gl.deleteBuffer(positionBuffer);
            }
        };
    }, []);
    return (
        <motion.div style={{ zIndex }}>
            <canvas className="canvas" ref={canvasRef} />
        </motion.div>
    );
};

export default GradientBG;
