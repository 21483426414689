import { usePresence } from 'framer-motion';
import { useEffect } from 'react';
import { useSetOpenedPopupsState } from '@/shared/atoms/opened-popups';
import {
    DEFAULT_MODE,
    DEFAULT_NAME,
    PageTransitionName,
    useIsPageTransitioningValue,
    usePageTransitionValue,
    useSetIsPageTransitioningState,
    useSetPageTransitionState,
} from '@/shared/atoms/page-transition';
import { leaveDefault } from './leave-default';
import { leaveInstant } from './leave-instant';
import { leaveMenu } from './leave-menu';
import { leaveNewsToNews } from './leave-news-to-news';

export type LeaveFn = (data: {
    targetElement?: Element | null;
    setOpenedPopups?: (popups: string[]) => void;
}) => Promise<void>;

const leaveFnMap: Record<PageTransitionName, LeaveFn> = {
    default: leaveDefault,
    instant: leaveInstant,
    leaveMenu: leaveMenu,
    leaveNewsToNews: leaveNewsToNews,
};

export const usePageTransition = () => {
    const { setOpenedPopups } = useSetOpenedPopupsState();
    const pageTransition = usePageTransitionValue();
    const setLeaveTransition = useSetPageTransitionState();
    const [isPresent, safeToRemove] = usePresence();
    const isTransitioning = useIsPageTransitioningValue();
    const setIsTransitioning = useSetIsPageTransitioningState();

    useEffect(() => {
        const applyDefaultTransition = () => {
            setTimeout(() => {
                setLeaveTransition({ mode: DEFAULT_MODE, name: DEFAULT_NAME });
            }, 0);
        };

        if (!isPresent && !isTransitioning) {
            setIsTransitioning(true);
            document.addEventListener('new-page-ready', applyDefaultTransition);

            leaveFnMap[pageTransition.name]({ targetElement: pageTransition.targetElement, setOpenedPopups })
                .then(() => {
                    safeToRemove();
                })
                .finally(() => {
                    setIsTransitioning(false);
                });
        }

        return () => {
            document.removeEventListener('new-page-ready', applyDefaultTransition);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setLeaveTransition, isPresent, pageTransition, safeToRemove, setIsTransitioning, setOpenedPopups]);
};
