import classnames from 'classnames';
import { useMotionValueEvent, useScroll } from 'framer-motion';
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { PAGES } from '@/consts';
import { useSetFullLogoState } from '@/shared/atoms/full-logo';
import { usePageDataValue, useSetPageDataState } from '@/shared/atoms/page-data';
import { useUtmParams } from '@/shared/hooks/use-utm/use-set-utm';
import { mergeRefs } from '@/shared/lib/utils/merge-refs';
import { usePageTransition } from '@/shared/transitions/page-transitions';

interface Props extends React.HTMLAttributes<HTMLElement> {
    bodyClass?: string;
    currentPageId?: number;
}

const DefaultLayout = forwardRef<HTMLDivElement, Props>(({ children, bodyClass, currentPageId, ...props }, ref) => {
    const internalRef = useRef<HTMLDivElement | null>(null);
    const setFullLogo = useSetFullLogoState();
    useImperativeHandle(ref, () => internalRef.current as HTMLDivElement);

    const setPageData = useSetPageDataState();
    const pageData = usePageDataValue();
    useEffect(() => {
        if (currentPageId && pageData?.pageId !== currentPageId) {
            setPageData({ pageId: currentPageId });
        }
    });

    const { scrollYProgress } = useScroll({
        target: internalRef,
        offset: ['start -30%', 'end end'],
    });

    useMotionValueEvent(scrollYProgress, 'change', (progress) => {
        if (
            bodyClass === 'participation-page' ||
            bodyClass === 'events-page' ||
            bodyClass === 'privacy-page' ||
            bodyClass === 'technological-page' ||
            bodyClass === 'summit-page'
        ) {
            return;
        }

        setFullLogo(progress > 0);
    });

    usePageTransition();
    useUtmParams();

    return (
        <div {...props} ref={mergeRefs([ref, internalRef])} className={classnames('page js-page', props.className)}>
            {children}
        </div>
    );
});

DefaultLayout.displayName = 'DefaultLayout';

export default DefaultLayout;
