import { animate } from 'framer-motion';
import { lockBodyScroll, unlockBodyScroll } from '@/shared/lib/utils/dom';
import { easeInOutCubic } from '@/shared/lib/utils/easings';
import { timeout } from '../lib/utils/timeout';

export const leaveMenu = ({
    setOpenedPopups,
}: {
    targetElement?: Element | null;
    setOpenedPopups?: (popups: string[]) => void;
}): Promise<void> =>
    new Promise((resolve) => {
        const menu = document.querySelector<HTMLElement>('.js-menu-popup');
        const whiteContainer = document.querySelector<HTMLElement>('.js-menu-popup-white-bg');
        const blueContainer = document.querySelector<HTMLElement>('.js-menu-popup-blue-bg');
        const blueLinks = menu?.querySelector<HTMLElement>('.js-menu-popup-blue-links');
        const items = menu?.querySelector<HTMLElement>('.menu-popup-items');
        const bottom = menu?.querySelector<HTMLElement>('.menu-popup-bottom');
        const top = menu?.querySelector<HTMLElement>('.menu-popup-top');
        let whiteScale: number;
        let blueScale: number;

        if (whiteContainer) {
            const elementWidth = whiteContainer.getBoundingClientRect().width;
            const windowWidth = window.innerWidth;
            whiteScale = windowWidth / elementWidth;
        }

        if (blueContainer) {
            const elementWidth = blueContainer.getBoundingClientRect().width;
            const windowWidth = window.innerWidth;
            blueScale = windowWidth / elementWidth;
        }

        lockBodyScroll();
        animate(1, 0, {
            duration: 0.3,
            onUpdate: (val) => {
                if (items) {
                    items.style.opacity = `${val}`;
                }
                if (bottom) {
                    bottom.style.opacity = `${val}`;
                }
                if (top) {
                    top.style.opacity = `${val}`;
                }
                if (blueLinks) {
                    blueLinks.style.opacity = `${val}`;
                }
            },
            onComplete: () => {
                if (whiteContainer && blueContainer) {
                    animate(1, whiteScale, {
                        ease: easeInOutCubic,
                        delay: 0.2,
                        duration: 0.6,
                        onUpdate: (val) => {
                            whiteContainer.classList.remove('menu-popup-opened');
                            whiteContainer.style.transform = `scaleX(${val})`;
                        },
                        onComplete: async () => {
                            whiteContainer.style.transformOrigin = 'right';
                            whiteContainer.style.left = 'unset';
                            whiteContainer.style.right = '0';
                            resolve();
                            await timeout(100);
                            unlockBodyScroll();
                            animate(whiteScale, 0, {
                                ease: easeInOutCubic,
                                duration: 1,
                                onUpdate: (val) => {
                                    whiteContainer.style.transform = `scaleX(${val})`;
                                },
                                onComplete: () => {
                                    if (setOpenedPopups) {
                                        setOpenedPopups([]);
                                    }
                                    whiteContainer.style.transformOrigin = 'left';
                                    whiteContainer.style.left = '0';
                                    whiteContainer.style.right = 'auto';
                                    whiteContainer.style.transform = '';
                                    whiteContainer.classList.add('menu-popup-closed');

                                    setTimeout(() => {
                                        if (items) {
                                            items.style.opacity = '1';
                                        }
                                        if (bottom) {
                                            bottom.style.opacity = '1';
                                        }
                                        if (top) {
                                            top.style.opacity = '1';
                                        }
                                    }, 1000);
                                },
                            });
                        },
                    });
                    animate(1, blueScale, {
                        ease: easeInOutCubic,
                        duration: 0.4,
                        onUpdate: (val) => {
                            blueContainer.style.transform = `translate3d(500px, 0, 0) scaleX(${val})`;
                            blueContainer.classList.remove('menu-popup-opened');
                        },
                        onComplete: () => {
                            animate(blueScale, 1, {
                                ease: easeInOutCubic,
                                duration: 1,
                                onUpdate: (val) => {
                                    blueContainer.style.transform = `scaleX(${val})`;
                                },
                                onComplete: () => {
                                    blueContainer.style.transform = '';

                                    setTimeout(() => {
                                        if (blueLinks) {
                                            blueLinks.style.opacity = '1';
                                            blueContainer.classList.add('menu-popup-closed');
                                        }
                                    }, 1000);
                                },
                            });
                        },
                    });
                } else {
                    unlockBodyScroll();
                    resolve();
                }
            },
        });
    });
